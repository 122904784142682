<template>
  <v-container fluid class="talk-container pa-4 pa-sm-6 pa-md-8">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <v-card elevation="2" class="chat-card">
          <v-card-text class="chat-window" ref="messageContainer">
            <div class="messages">
              <div v-for="message in messages" :key="message.id"
                :class="{ 'my-message': message.isMine, 'other-message': !message.isMine }"
                @mouseenter="showMenu = message.id" @mouseleave="showMenu = null">
                <span style="white-space: pre-wrap;">{{ message.text }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="chat-input">
            <v-text-field v-model="newMessage" label="输入问题，按回车键发送" filled clearable @keyup.enter="sendMessage"
              ref="messageInput" dense flat hide-details rounded solo class="custom-text-field"></v-text-field>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from '@/utils/request';

export default {
  name: "Talk",
  data() {
    return {
      newMessage: '',
      messages: [],
      isSending: false,
      showMenu: null,
    };
  },
  methods: {
    scrollToEnd() {
      let container = this.$refs.messageContainer;
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    sendMessage() {
      if (this.isSending) {
        return;
      }
      this.isSending = true;

      const newMessageId = this.messages.length + 1;
      this.addMessage(this.newMessage, true, newMessageId);
      this.addMessage("机器人正在思考，请耐心等待……", false, newMessageId + 1);
      this.scrollToEnd();

      const formData = new FormData();
      formData.append('question', this.newMessage);
      const token = localStorage.getItem('token');
      formData.append('token', token);

      service.post("/api/wiki/talk", formData).then(response => {
        const data = response.data;
        this.messages[this.messages.length - 2].id = data.question.id;
        this.messages[this.messages.length - 1].text = data.answer.content;
        this.messages[this.messages.length - 1].id = data.answer.id;

        this.scrollToEnd();
        this.isSending = false;
      });

      this.newMessage = '';
    },
    addMessage(text, isMine, id) {
      this.messages.push({
        id: id,
        text: text,
        isMine: isMine
      });
      this.scrollToEnd();
    },
    loadChatHistory() {
      const token = localStorage.getItem('token');
      service.get(`/api/wiki/talklog?token=${token}`).then(response => {
        this.messages = response.data.map((msg, index) => ({
          id: msg.id,
          text: msg.content,
          isMine: msg.role === 'question'
        }));
        this.scrollToEnd();
      });
    }
  },
  mounted() {
    this.$refs.messageInput.focus();
    this.loadChatHistory();
  },
  created() {
  },
};
</script>

<style scoped>
.talk-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
}

.chat-card {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 56px); /* 减去顶部导航栏、页脚和页面padding的高度 */
}

.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.my-message,
.other-message {
  max-width: 70%;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-message {
  background-color: #DCF8C6;
  align-self: flex-end;
}

.other-message {
  background-color: #FFFFFF;
  align-self: flex-start;
}

.chat-input {
  padding: 10px;
}

.custom-text-field >>> .v-input__slot {
  background-color: #FFFFFF !important;
}
</style>
